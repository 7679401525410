import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";

export default ({ date, setDate }) => {
  return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          openTo="month"
          views={["month", "year"]}
          value={date}
          onChange={setDate}
        />
      </MuiPickersUtilsProvider>
  );
};
