import React, { useState } from "react";
import {
  TextField,
  makeStyles,
  Button,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "60%",
    margin: "auto",
  },
  textField: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    width: "25ch",
    marginTop: "27px",
  },
  button: {
    width: "60%",
    margin: "23px 10px 23px 10px",
  },
  footer: {
    display: "flex",
  },
  labelMargin: {
    marginLeft: "0px",
  },
}));

export default ({ onSubmit, onCancel, company }) => {
  const classes = useStyles();
  const [isExternal, setIsExternal] = useState(
    (company && company.isExternal) || false
  );
  const { register, handleSubmit } = useForm();
  const setChecked = (event) => {
    setIsExternal(event.target.checked);
  };
  const saveData = (data) => {
    data.isExternal = isExternal;
    onSubmit(data);
  };
  return (
    <form onSubmit={handleSubmit(saveData)}>
      <div className={classes.root}>
        <TextField
          name="code"
          id="outlined-code-input"
          label="Code"
          type="number"
          variant="outlined"
          className={classes.textField}
          inputRef={register}
          defaultValue={company ? company.code : ""}
          disabled={company ? true : false}
        />
        <TextField
          name="name"
          id="outlined-name-input"
          label="Name"
          type="text"
          variant="outlined"
          className={classes.textField}
          inputRef={register}
          defaultValue={company ? company.name : ""}
        />
        <TextField
          name="hebName"
          id="outlined-hebName-input"
          label="HebName"
          type="text"
          variant="outlined"
          className={classes.textField}
          inputRef={register}
          defaultValue={company ? company.hebName : ""}
        />
        <TextField
          name="fullName"
          id="outlined-fullName-input"
          label="FullName"
          type="text"
          variant="outlined"
          className={classes.textField}
          inputRef={register}
          defaultValue={company ? company.fullName : ""}
        />
        <FormControlLabel
          className={classes.labelMargin}
          label="Is External"
          labelPlacement="start"
          control={
            <Switch
              name="isExternal"
              color="primary"
              checked={isExternal}
              onChange={setChecked}
            />
          }
        />
        <div className={classes.footer}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
          >
            Save
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </form>
  );
};
