import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  IconButton,
  Switch,
  FormControlLabel
} from "@material-ui/core/";
import EditIcon from "@material-ui/icons/Edit";
const useStyles = makeStyles({
  root: {
    width: "60%",
    margin: "auto",
  },
  container: {
    marginTop: "0.2%"
  },
  table: {
    minWidth: 650,
  },
});
export default ({ companies, edit }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Heb Name</TableCell>
              <TableCell>Full Name</TableCell>
              <TableCell>Is External</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {companies &&
              Object.keys(companies) &&
              Object.keys(companies).map((key, i) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {key}
                  </TableCell>
                  <TableCell> {companies[key].name}</TableCell>
                  <TableCell align="right">{companies[key].hebName}</TableCell>
                  <TableCell>{companies[key].fullName}</TableCell>
                  <TableCell>
                    <FormControlLabel
                      labelPlacement="start"
                      control={
                        <Switch
                          disabled
                          name="isExternal"
                          color="primary"
                          checked={companies[key].isExternal || false}
                        />
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => edit(companies[key], key)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
