import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Header from "./Header";
import TravellingAllowance from "./travelling-allowence/TravellingAllowance";
import Invoices from "./invoices/Invoices";
import HoursTrack from "./hours-track/HoursTrack";
import Archive101 from "./archive101/Archive101"
import CompensationCalculation from "./CompensationCalculation";

export default () => {
  return (
    <div className="App">
      <Router>
        <Header></Header>
        <Switch>
          <Route path="/travelling-allowance">
            <TravellingAllowance />
          </Route>
          <Route path="/invoices">
            <Invoices />
          </Route>
          <Route path="/hours-track">
            <HoursTrack />
          </Route>
          <Route path="/archive-101">
            <Archive101 />
          </Route>
          <Route path="/compensation-calculation">
            <CompensationCalculation />
          </Route>
          <Redirect exact from="/" to="/travelling-allowance" />
        </Switch>
      </Router>
    </div>
  );
};
