import React, { useState } from "react";
import AttendanceDataLoader from "./AttendanceDataLoader";
import CostingDataLoader from "./CostingDataLoader";
import DownloadHoursReport from "./DownloadHoursReport";
import DateInput from "../DateInput";
import { Box } from "@material-ui/core";
import { addMonths } from "date-fns";

export default () => {
  const [attendance, setAttendance] = useState({});
  const [costing, setCosting] = useState({});
  const [date, setDate] = useState(addMonths(new Date(), -1));

  return (
    <>
      <AttendanceDataLoader setData={setAttendance}></AttendanceDataLoader>
      <CostingDataLoader setData={setCosting}></CostingDataLoader>
      <Box mt="5vh">
        <DateInput date={date} setDate={setDate}></DateInput>
      </Box>
      <DownloadHoursReport
        attendance={attendance}
        costing={costing}
        date={date}
      ></DownloadHoursReport>
    </>
  );
};
