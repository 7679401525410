import React from "react";
import FabUploadFile from "../FabUploadFile";
import XLSX from "xlsx";
import { readFile } from "../utils";

const comment = "עבודה מהמשרד";

export default ({ setData }) => {
  const upload = (files) => {
    readFile(files[0]).then((data) => {

      const workbook = XLSX.read(data, { type: "array" });
      const employeesData = {};

      Object.values(workbook.Sheets).forEach((sheet) => {
        const employeeNumber = sheet.A7.v === ": ת.ז" ? sheet.B7.v : sheet.C7.v; // employee number could be in B7 or in C7 - depends if there is תז or not.
        employeesData[employeeNumber] = Object.values(sheet).filter(
          (c) => c.v && c.v.indexOf && c.v.indexOf(comment) !== -1
        ).length;
      });

      setData(employeesData);
    });
  };

  return (
    <FabUploadFile
      accept=".xls"
      upload={upload}
      text="Upload Alon Report"
    ></FabUploadFile>
  );
};
