import React, { useState } from "react";
import SearchBar from "material-ui-search-bar";

export default ({ data, setFilteredData }) => {
    const [searched, setSearched] = useState("");

    const requestSearch = (searchedVal) => {
        const filteredRows = Object.entries(data).reduce((acc, blobs) => {
            let f = blobs[1].filter((b) => b.toLowerCase().includes(searchedVal.toLowerCase()));
            if (f && f.length) {
                acc[blobs[0]] = f;
            }
            return acc;
        }, []);
        setFilteredData(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };

    return (
        <SearchBar
            value={searched}
            onChange={(searchVal) => requestSearch(searchVal)}
            onCancelSearch={() => cancelSearch()}
        />
    )
}