import React from "react";
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import { Button } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useStyles } from "./SummaryClasses";
import formatDate from "date-fns/format";


export default ({ data, year }) => {
    const classes = useStyles();

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const getJsonData = () => {
        const date = new Date();
        const obj = [];
        obj.push([`Invoices Summary - ${year}`]);
        obj.push(["Date: ", formatDate(date, "dd/MM/yyyy")])
        obj.push([]);
        data.forEach((r) => {
            let row = {}, i = 0;
            Object.values(r).forEach(cell => row[i++] = cell.text)
            obj.push(row);
        })
        return obj;
    }

    const saveFile = () => {
        const json = getJsonData();
        const ws = XLSX.utils.json_to_sheet(json, { skipHeader: true });
        const wb = { Sheets: {}, SheetNames: [year] };
        wb.Sheets[year] = ws;
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const fileData = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(fileData, `SUMMARY_${year}.xlsx`);
    }

    return (
        <>
            <Button
                variant="contained"
                color="default"
                className={classes.button}
                startIcon={<CloudDownloadIcon />}
                onClick={saveFile}
            >
                Export To Excel
            </Button>
        </>
    )
}