import React from "react";
import {
    TableContainer,
    Paper,
    Table,
    // TableHead,
    TableBody,
    TableRow,
    TableCell
} from "@material-ui/core";
import { useStyles } from "./SummaryClasses";
import { withStyles } from "@material-ui/core/styles";
import ExportToExcel from "./ExportToExcel";
import { isEmpty } from "../utils";


export default ({ data, year }) => {
    const classes = useStyles({});

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        body: {
            fontSize: 12,
            width: "6%"
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    return (
        <>
            {!isEmpty(data) &&
                <div className={classes.excelArea}>
                    <ExportToExcel data={data} year={year} />
                </div>
            }
            <TableContainer component={Paper} className={classes.container}>
                <Table className={classes.table} aria-label="Summary Table">
                    <TableBody>
                        {Object.entries(data).map(([name, arr]) =>
                            <StyledTableRow key={name}>
                                {Object.values(arr).map((v, k) => {
                                    return v.type !== "none" ?
                                        <StyledTableCell
                                            key={k}
                                            rowSpan={v.type === "company" ? 2 : 1}
                                            colSpan={v.type === "footer" ? 2 : 1}
                                            className={classes[v.type]}>
                                            {v.text}
                                        </StyledTableCell> : null;
                                }
                                )}
                            </StyledTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}