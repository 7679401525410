import React from "react";
import FabUploadFile from "../FabUploadFile";
import { BlobServiceClient } from "@azure/storage-blob";

export default ({ containerName, setLoading, setAlertData, fetchData }) => {
    const uploadInvoice = (file) => {
        return new Promise((resolve, reject) => {
            try {
                const blobService = new BlobServiceClient(
                    process.env.REACT_APP_BLOB_SERVICE_URL
                );
                const container = blobService.getContainerClient(containerName);
                const blockBlobClient = container.getBlockBlobClient(file.name);
                blockBlobClient.uploadBrowserData(file).then(_ => {
                    setAlertData({
                        open: true,
                        message: <>The file <b>{file.name}</b> was uploaded successfully</>,
                        title: "success",
                        severity: "success"
                    });
                    resolve();
                })
            }
            catch (error) {
                setAlertData({
                    open: true,
                    message: <>There was an error uploading the file <b>{file.name}</b></>,
                    title: "Error",
                    severity: "error"
                });
                reject()
            }
        })
    }

    const upload = (files) => {
        setLoading(true);
        Promise.all(Object.values(files).map(file => {
            return new Promise((resolve) => {
                uploadInvoice(file)
                    .then(_ => resolve(true))
                    .catch(_ => resolve(true));
            })
        })
        ).then(_ => {
            setLoading(false);
            fetchData();
        });
    };

    const extractContainerDescription = containerName =>
        containerName.substring(0, containerName.lastIndexOf('-')).replace(/-/g, ' ').toUpperCase();

    return (
        <>
            <FabUploadFile
                upload={upload}
                text={containerName ? `Upload new files to ${extractContainerDescription(containerName)}` : "Upload new files"}
                required
                multiple
                disabled={!containerName}
            />
        </>
    );
}