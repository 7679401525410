import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button
} from "@material-ui/core";

export const defaultDialogData = {
    open: false,
    message: "",
    title: "",
    agree: "",
    handleAgree: () => { },
    disagree: "",
    handleDisagree: () => { },
};

export default ({ dialogData }) => {
    const [state, setState] = useState(defaultDialogData)

    useEffect(() => {
        setState(dialogData);
    }, [dialogData]);

    return (
        <Dialog
            open={state.open}
            onClose={state.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {state.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {state.message}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={state.handleDisagree} color="primary">
                    {state.disagree}
                </Button>
                <Button onClick={state.handleAgree} color="primary" autoFocus>
                    {state.agree}
                </Button>
            </DialogActions>
        </Dialog>
    )
}