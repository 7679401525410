import React from "react";
import FabUploadFile from "../FabUploadFile";
import XLSX from "xlsx";
import parseDate from "date-fns/parse";
import {readFile} from "../utils";

const STRING = 'ת.ערך בין';
const extractDate = (line) => {
  const date = line.split(STRING)[1].split(' ')[1].split('-')[0];
  return parseDate(date, 'dd/MM/yy', new Date());
}

export default ({ setData, setDate }) => {

  //This function read the data from the excel file was uploaded,  convert the data to json object and returns it.
  const upload = (files) => {

    readFile(files[0]).then((buffer) => {
      const workbook = XLSX.read(buffer, { type: "array" });
      Object.values(workbook.Sheets).forEach((sheet) => {

        const date = extractDate(sheet['A3'].v);
        console.log(date)
        setDate(date);

        const edge = sheet["!ref"].split(":")[1]; // get the last cell in sheet
        const data = XLSX.utils.sheet_to_json(sheet, {
          range: `B4:${edge}`
        });
        setData(data);
      });
    });
  };

  return (
    <FabUploadFile
      accept=".xls,.xlsx"
      upload={upload}
      text="העלאת מאזן"
      required />
  );
};

