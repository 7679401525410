import React, { useState, useEffect } from "react";
import { Fab, CircularProgress, Backdrop } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { BlobServiceClient } from "@azure/storage-blob";
import formatDate from "date-fns/format";
import { addMonths, isAfter } from "date-fns";
import { format } from "util";
import { useStyles } from "../Styles";
import { isEmpty } from "../utils";
import externalCompanies from "../data/external-companies.json";
import expenseTypes from "../data/expense-types.json";
import { download } from "./DownloadInvoice";
import Alert from "../Alert";

const ACCOUNT = "חשבון";
const CODE = "מיון";
const DESCRIPTION = "שם חשבון";
const SALARIES = "Salaries (cost)";
const CREDIT = "זכות";
const DEBIT = "חובה";
const CB_GENERAL_CODES = ["8000", "8001", "9960"]; //general codes, for which an invoice is not required


const isRecognizedCompany = (line) => {
    if (CB_GENERAL_CODES.includes(line[CODE].toString()) || externalCompanies[line[CODE].toString()])
        return true;
    return false;
}

const getAssociatedType = (types, line) => {
    const type = Object.entries(types).find(([k, v]) => v.codes.includes(line[ACCOUNT].toString().substr(4)));
    return type && { code: type[0], ...type[1] };
}

const addComment = (type, date) => {
    if (type.description === SALARIES) {
        return formatDate(addMonths(date, -1), "MMM-yy");
    }
};

const getAmount = (line) => {
    return line.hasOwnProperty(CREDIT) ? -line[CREDIT] : line[DEBIT];
}

const addSubCompanyProperties = (expense) => {
    expense.totalSum = (expense.sum + (expense.subCompanySum || 0)).toFixed(2);
    expense.subCompanySum = (expense.subCompanySum || 0).toFixed(2);
}

const downloadInvoices = (invoices, rate, date) => {
    return Object.entries(invoices).map(([companyName, invoice]) => {
        invoice.expenses = Object.values(invoice.expenses);
        const hasSubCompay = invoice.expenses.some(e => e.subCompanySum);
        invoice.expenses.forEach(expense => {
            if (hasSubCompay) {
                addSubCompanyProperties(expense);
            }
            expense.sumDollar = ((expense.totalSum || expense.sum) / rate).toFixed(2);
            expense.sum = expense.sum.toFixed(2);
        });
        return download(invoice, companyName, date);
    });
}

export default ({ maazanData, date }) => {
    const classes = useStyles({});
    const [loading, setLoading] = useState(false);
    const [companies, setCompanies] = useState();
    const [error, setError] = useState(false);
    const [alertData, setAlertData] = useState({
        open: true,
        message: null,
        title: "Error",
        severity: "error"
    });

    const setAlertMessage = (description) => {
        setAlertData({
            ...alertData,
            message: <>
                The MAAZAN contains problematic data, please contact the BB Dev team for support.
                <br />
                Error line description:
                <strong>{description}</strong>
            </>
        });
    }

    useEffect(() => {
        const blobService = new BlobServiceClient(
            process.env.REACT_APP_BLOB_SERVICE_URL
        );
        const container = blobService.getContainerClient("companies");
        const blobClient = container.getBlobClient(process.env.REACT_APP_BLOB_NAME);
        fetch(blobClient.url)
            .then(res => res.json())
            .then(data => {
                setCompanies(data);
            })
    }, []);

    const invoices = {};

    const getAssociatedCompany = (line) => {
        // eslint-disable-next-line
        const companyCode = Object.keys(companies).find(k => k.toString() == line[ACCOUNT].toString().substr(0, 4) || k.toString() == line[CODE]);
        return companies[companyCode];
    }

    const isSharedResource = (company) => {
        return company.sharedFor && company.sharedFor.length > 0;
    }

    const getAssociatedParentCompany = (line) => {
        // eslint-disable-next-line
        return Object.values(companies).find(v => v.subCompany == line[ACCOUNT].toString().substr(0, 4) || v.subCompany == line[CODE]);
    }

    const addCompanyInvoice = (company, rate) => {
        return {
            company: company.fullName || company.name,
            companyAddress: "1125 Ocean Avenue",
            to: company.addressee || "Mr. Charles Celnik",
            deliveryType: "VIA E-MAIL",
            date: formatDate(date, "MMMM yyyy"),
            rate: rate,
            template: company.template || "",
            fee: company.fee || 4.5,
            expenses: {},
        };
    }

    const addCompanyType = (company, type) => {
        return {
            description: type.description + (company.expenseSuffix || ''),
            sum: 0,
            comment: addComment(type, date) || null,
        };
    }

    const addExpense = (company, type, amount, isSubCompany, rate) => {
        if (!invoices[company.name]) {
            invoices[company.name] = addCompanyInvoice(company, rate);
        }

        if (!invoices[company.name].expenses[type.code]) {
            invoices[company.name].expenses[type.code] = addCompanyType(company, type);
        }

        const expense = invoices[company.name].expenses[type.code];
        if (isSubCompany) {
            if (!expense.subCompanySum)
                expense.subCompanySum = 0;
            expense.subCompanySum += amount;
        }
        else {
            expense.sum += amount;
        }
    }

    const downloadReport = () => {
        let hasError = false;
        setLoading(true);

        const d = new Date();
        let selectedDate;
        if (isAfter(date, d))
            selectedDate = d;
        else selectedDate = date;

        const url = format(process.env.REACT_APP_URL_EXCHANGE_RATES_URL, formatDate(selectedDate, "yyyy-MM-dd"));
        fetch(url)
            .then((response) => response.json())
            .then((result) => {
                const rate = result.rates.ILS.toFixed(3);

                Object.values(maazanData).filter(l => l[ACCOUNT]).every(line => {
                    let company = getAssociatedCompany(line);
                    let isSubCompany = false;
                    if (!company) {
                        company = getAssociatedParentCompany(line);
                        isSubCompany = true;
                    }
                    if (company && company.isExternal)
                        return true;
                    if (!company) {
                        if (isRecognizedCompany(line))
                            return true;
                        else {
                            setError(true);
                            setAlertMessage(line[DESCRIPTION]);
                            hasError = true;
                            return false;
                        }
                    }

                    const types = Object.values({ ...expenseTypes, ...company.expenseTypes });
                    const type = getAssociatedType(types, line);
                    if (!type) {
                        setError(true);
                        setAlertMessage(line[DESCRIPTION]);
                        hasError = true;
                        return false;
                    }

                    let amount = getAmount(line);

                    if (isSharedResource(company)) {
                        let count = company.sharedFor.length;
                        company.sharedFor.forEach(code => {
                            if (!CB_GENERAL_CODES.includes(code)){
                                let sCode = Object.keys(companies).find(k => k.toString() === code);
                                let sharedCompany = companies[sCode];
                                addExpense(sharedCompany, type, amount / count, isSubCompany, rate);
                            }
                        })
                    }
                    else {
                        addExpense(company, type, amount, isSubCompany, rate);
                    }

                    return true;
                });

                if (!hasError) {
                    const jobs = downloadInvoices(invoices, rate, date);
                    Promise.all(jobs).then(_ => setLoading(false));
                }
            });
    }

    return (
        <>
            <Fab
                disabled={isEmpty(maazanData)}
                display="block"
                color="primary"
                aria-label="download"
                variant="extended"
                className={classes.fab}
                onClick={downloadReport}
            >
                <CloudDownloadIcon className={classes.fabIcon} />
                הורד חשבוניות
            </Fab>
            {loading &&
                <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            }
            {error &&
                <Alert
                    alertData={alertData}
                    closeModal={() => setAlertData({ ...alertData, open: false })}
                />}
        </>
    );
}