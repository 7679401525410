import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Alert from "@material-ui/lab/Alert";
import { useStyles } from "../Styles";
import { isEmpty, get, setAutoWidth, setRTL } from "../utils";
import XLSX from "xlsx";
import formatDate from "date-fns/format";
import { getYear } from "date-fns";
import useCompanies, { getCompany } from "../companies/useCompanies";

const addHoursSheet = (workbook, data) => {
  const titles = [
    'סה"כ',
    "סך לשעה",
    "כמות השעות",
    "חברה לחיוב בחשבשבת",
    "חברה שעבדה עבורה החודש",
    "חברה לזיכוי בחשבשבת",
    "מחלקה בסיסית במיכפל",
    "עובד",
    "מספר עובד",
  ];

  const aoa = [
    titles,
    ...data.map((item) => {
      return [
        item.charge.toFixed(2),
        item.hourlyRate.toFixed(2),
        item.projectHours.toFixed(2),
        item.company.hoursTrackName,
        item.company.hebName,
        item.baseCompany && item.baseCompany.hebName,
        item.baseDepartment,
        item.fullName,
        item.number,
      ];
    }),
  ];
  const sheet = XLSX.utils.aoa_to_sheet(aoa);
  setAutoWidth(sheet, aoa);
  XLSX.utils.book_append_sheet(workbook, sheet, "Project Hours Per Employee");
};

const addCompanyChargesSheet = (workbook, data) => {
  const titles = ["שם חברה", "חיוב", "זיכוי"];

  const charges = {};
  data.forEach((item) => {
    if (isFinite(item.charge)) {
      const code1 = item.company.code;
      if (!charges.hasOwnProperty(code1))
        charges[code1] = {
          charge: 0,
          refund: 0,
          name: item.company.hebName || item.company.name || item.company.hoursTrackName
        };
      charges[code1].charge += item.charge;

      const code2 = item.baseCompany.code;
      if (!charges.hasOwnProperty(code2))
        charges[code2] = {
          charge: 0,
          refund: 0,
          name:
            item.baseCompany.hebName ||
            item.baseCompany.name ||
            item.baseCompany.hoursTrackName
        };
      charges[code2].refund += item.charge;
    }
  });

  const aoa = [
    titles,
    ...Object.values(charges).map((item) => [
      item.name,
      item.charge.toFixed(2),
      item.refund.toFixed(2)
    ]),
  ];
  const sheet = XLSX.utils.aoa_to_sheet(aoa);
  setAutoWidth(sheet, aoa);
  XLSX.utils.book_append_sheet(workbook, sheet, "Charges & Refund");
};

export default ({ costing, attendance, date }) => {
  const classes = useStyles({});
  const [missing, setMissing] = useState([]);
  const companies = useCompanies();

  const download = () => {
    const month = date.getMonth() + 1;
    const year = getYear(date);
    get(`${process.env.REACT_APP_HOURS_TRACK_ENDPOINT}&month=${month}&year=${year}`).then((projectHoursData) => {
      const workbook = XLSX.utils.book_new();

      setMissing(projectHoursData.filter((item) => !item.michpalNumber));

      const data = projectHoursData
        .filter((item) => !!item.michpalNumber && !!item.totalMinutes)
        .map((item) => {
          const number = item.michpalNumber;
          const employeeCost = costing[number] || {};
          const employeeAttendance = attendance[number] || {};

          const hourlyRate =
            employeeCost.totalCost / employeeAttendance.workingHours;
          const projectHours = item.totalMinutes / 60;
          const charge = projectHours * hourlyRate;

          const company = {
            hoursTrackName: item.company,
            ...getCompany(companies, item.company),
          };

          return {
            ...item,
            ...employeeCost,
            ...employeeAttendance,
            hourlyRate,
            projectHours,
            charge,
            company,
            number,
          };
        })
        .filter((item) => item.company.code !== item.baseCompany.code);

      addCompanyChargesSheet(workbook, data);

      addHoursSheet(workbook, data);

      setRTL(workbook);

      XLSX.writeFile(
        workbook,
        `HoursPerProject-${formatDate(date, "MMMM-yyyy")}.xlsx`
      );
    });
  };

  return (
    <div>
      <Fab
        disabled={isEmpty(attendance) || isEmpty(costing)}
        display="block"
        color="primary"
        aria-label="download"
        variant="extended"
        className={classes.fab}
        onClick={download}
      >
        <CloudDownloadIcon className={classes.fabIcon} />
        Download Project \ Hours Report
      </Fab>
      <div classes={classes.alert}>
        {!isEmpty(missing) &&
          missing.map((item, i) => (
            <Alert key={i} severity="warning">
              Missing michpal number for employee{" "}
              <strong>{item.employeeName}</strong>
            </Alert>
          ))}
      </div>
    </div>
  );
};
