import React, { useState } from "react";
import { FormGroup } from "@material-ui/core";
import { useStyles } from "./InvoiceClasses";
import MaazanLoader from "./MaazanLoader";
import GenerateInvoices from "./GenerateInvoices";
import Tabs from "./Tabs";
import InvoicesArchive from "../archive/InvoicesArchive";
import Companies from "../companies/Companies";
import Summary from "../summary/Summary";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";


export default () => {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [date, setDate] = useState(new Date());

  return (
    <Router>
      <Tabs></Tabs>
      <Switch>
        <Route path="/invoices/new">
          <FormGroup className={classes.root}>
            <MaazanLoader setData={setData} setDate={setDate} />
            <GenerateInvoices maazanData={data} date={date} />
          </FormGroup>
        </Route>
        <Route path="/invoices/archive">
          <InvoicesArchive />
        </Route>
        <Route path="/invoices/companies">
          <Companies />
        </Route>
        <Route path="/invoices/summary">
          <Summary />
        </Route>
        <Redirect exact from="/invoices" to="/invoices/new" />
      </Switch>
    </Router>
  );
};
