import React, { useState, useEffect, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Companies from "./CompaniesTable";
import { Button, makeStyles } from "@material-ui/core";
import CompanyModal from "./CompanyModal";
import Alert from "../Alert";
const blobService = new BlobServiceClient(
  process.env.REACT_APP_BLOB_SERVICE_URL
);
const container = blobService.getContainerClient("companies");
const useStyles = makeStyles((theme) => ({
  buttonModal: {
    float: "left",
    margin: "10px",
  },
}));
export default () => {
  const [companies, setCompanies] = useState();
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState();
  const [alertData, setAlertData] = useState({
    open: false,
    message: "",
    title: "",
    severity: "",
  });
  const isFirstRun = useRef(true);
  const classes = useStyles();
  useEffect(() => {
    const blobClient = container.getBlobClient(process.env.REACT_APP_BLOB_NAME);
    fetch(blobClient.url)
      .then((res) => res.json())
      .then((data) => {
        setCompanies(data);
      });
  }, []);
  useEffect(() => {
    if (open === false && selectedCompany) setSelectedCompany(null);
  }, [selectedCompany, open]);
  useEffect(() => {
    const uploadData = () => {
      const content = JSON.stringify(companies);
      const blockBlobClient = container.getBlockBlobClient(
        process.env.REACT_APP_BLOB_NAME
      );
      blockBlobClient
        .upload(content, content.length)
        .then(() => {
          setOpen(false);
          setAlertData({
            open: true,
            message: "The upload of the companies has been successful",
            title: "success",
            severity: "success",
          });
        })
        .catch((e) => console.error(e));
    };
    if (isFirstRun.current && companies != null) {
      isFirstRun.current = false;
      return;
    }
    if (companies != null) uploadData();
  }, [companies]);

  const onSubmit = (data) => {
    if (selectedCompany) editCompany(data);
    else addCompany(data);
  };
  const editCompany = (data) => {
    const newList = { ...companies };
    newList[selectedCompany.code] = {
      ...selectedCompany,
      name: data.name,
      hebName: data.hebName,
      fullName: data.fullName,
      isExternal: data.isExternal,
    };
    setCompanies(newList);
  };
  const addCompany = (data) => {
    setCompanies({
      [data.code]: {
        name: data.name,
        hebName: data.hebName,
        fullName: data.fullName,
        isExternal: data.isExternal,
      },
      ...companies,
    });
  };
  const openEditModal = (company, key) => {
    company["code"] = key;
    setSelectedCompany(company);
    setOpen(true);
  };
  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        className={classes.buttonModal}
      >
        add company
      </Button>
      <Companies companies={companies} edit={openEditModal} />
      <CompanyModal
        onSubmit={onSubmit}
        selectedCompany={selectedCompany}
        open={open}
        handleClose={() => setOpen(false)}
      />
      <Alert
        closeModal={() => setAlertData({ open: false })}
        alertData={alertData}
      ></Alert>
    </div>
  );
};
