import React from "react";
import Fab from "@material-ui/core/Fab";
import {useStyles} from "../Styles";

import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import XLSX from "xlsx";
import { setRTL, setAutoWidth, isEmpty } from "../utils";

const calculateTravelCosts = (officeDaysPerEmployee, travelRates) => {
  return travelRates.map((i) => {
    const officeDays = officeDaysPerEmployee[i.number] || 0;
    const payment = calculatePayment(i.dailyRate, i.monthlyRate, officeDays);
    
    return [i.number, payment, i.name, officeDays, i.dailyRate, i.monthlyRate];
  });
};

const calculatePayment = (dailyRate, monthlyRate, officeDays) => {
  if(isNaN(dailyRate))
    return monthlyRate;
  const paymentPerDay = dailyRate * officeDays;
  if(isNaN(monthlyRate))
    return paymentPerDay;
  return Math.min(dailyRate * officeDays, monthlyRate);
}

export default ({ officeDays, travelRates }) => {
  const classes = useStyles({});

  const downloadReport = () => {
    const workbook = XLSX.utils.book_new();
    setRTL(workbook);

    const data = [
      [
        "Employee Number",
        "Travel Payment",
        "Employee Name",
        "Office Days",
        "Daily Rate",
        "Monthly Rate",
      ],
    ].concat(calculateTravelCosts(officeDays, travelRates));

    const sheet = XLSX.utils.aoa_to_sheet(data);
    setAutoWidth(sheet, data)

    XLSX.utils.book_append_sheet(workbook, sheet, "Travel Costs");

    XLSX.writeFile(workbook, "Travel_Payments.xlsx");
  };

  return (
    <div>
      <Fab
        disabled={isEmpty(officeDays) || travelRates.length === 0}
        display="block"
        color="primary"
        aria-label="Upload"
        variant="extended"
        className={classes.fab}
        onClick={downloadReport}
      >
        <CloudDownloadIcon className={classes.fabIcon} />
        Download Report
      </Fab>
    </div>
  );
};
