import React from "react";
import FabUploadFile from "../FabUploadFile";
import { BlobServiceClient } from "@azure/storage-blob";
import { Box, Typography } from '@material-ui/core';
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { MONTHS_LIST } from "../MonthsList";

const useStyles = makeStyles((theme) =>
    createStyles({
        whiteText: {
            color: "white"
        }
    })
);

export default ({ year, month, setLoading, setAlertData, fetchData }) => {
    const classes = useStyles();

    const getFileName = (file) => {
        const companyName = file.name.split('.')[0];
        const monthNum = (MONTHS_LIST.indexOf(month) + 1).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        });
        const yearNum = year.slice(-2);
        return `${companyName} Invoice ${monthNum}-${yearNum}.xlsx`;
    }

    const uploadInvoice = (file, fileName) => {
        return new Promise((resolve, reject) => {
            try {
                const blobService = new BlobServiceClient(
                    process.env.REACT_APP_BLOB_SERVICE_URL
                );
                const container = blobService.getContainerClient("invoices");
                const blockBlobClient = container.getBlockBlobClient(year + "/" + month + "/" + fileName);
                blockBlobClient.uploadBrowserData(file).then(_ => {
                    setAlertData({
                        open: true,
                        message: <>The file <b>{fileName}</b> was uploaded successfully</>,
                        title: "success",
                        severity: "success"
                    });
                    resolve();
                })
            }
            catch (error) {
                setAlertData({
                    open: true,
                    message: <>There was an error uploading the file <b>{fileName}</b></>,
                    title: "Error",
                    severity: "error"
                });
                reject()
            }
        })
    }

    const upload = (files) => {
        setLoading(true);
        Promise.all(Object.values(files).map(file => {
            return new Promise((resolve) => {
                const fileName = getFileName(file);
                uploadInvoice(file, fileName)
                    .then(_ => resolve(true))
                    .catch(_ => resolve(true));
            })
        })
        ).then(_ => {
            setLoading(false);
            fetchData();
        });
    };

    return (
        <>
            <FabUploadFile
                accept=".xls,.xlsx"
                upload={upload}
                text={month && year ? `Upload new invoices to ${month} ${year}` : "Upload new invoices"}
                required
                multiple
                disabled={!month || !year}
            />
            <Box display="flex" justifyContent="center" bgcolor="default" p={1} m={1}>
                <Typography variant="body2" color="textSecondary" component="p" className={!(month && year) ? classes.whiteText : null}>
                    Please note: When uploading a new file, files must be uploaded with the company name <br />
                    (no additional text in the file name), for example: CityBook.xlsx
                </Typography>
            </Box>
        </>
    );
}