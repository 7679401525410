import React from "react";

import FabUploadFile from "../FabUploadFile";
import XLSX from "xlsx";
import { readFile } from "../utils";

const EMPLOYEE_NUMBER = "מספר עובד";
const TOTAL_HOURS = 'ש"ע בפועל';

export default ({ setData }) => {
  const upload = (files) => {
    readFile(files[0]).then((data) => {
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils
        .sheet_to_json(sheet)
        .reduce((accumulator, row) => {
          const employeeNumber = row[EMPLOYEE_NUMBER];
          accumulator[employeeNumber] = { workingHours: row[TOTAL_HOURS] };
          return accumulator;
        });

      setData(rows);
    });
  };

  return (
    <FabUploadFile
      accept=".xlsx"
      upload={upload}
      text="Upload Michpal Attendance"
    ></FabUploadFile>
  );
};
