import React, { useState } from "react";

import AlonLoader from "./AlonLoader";
import MichpalLoader from "./MichpalLoader";
import GenerateReport from "./GenerateReport";

export default () => {
  const [officeDays, setOfficeDays] = useState({});
  const [travelRates, setTravelRates] = useState([]);

  return (
    <>
      <AlonLoader setData={setOfficeDays}></AlonLoader>
      <MichpalLoader setData={setTravelRates}></MichpalLoader>
      <GenerateReport officeDays={officeDays} travelRates={travelRates} ></GenerateReport>
    </>
  );
};
