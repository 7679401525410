import React, { useRef } from 'react';
import { useStyles } from './Styles';
import Fab from '@material-ui/core/Fab';
import CloudUpload from '@material-ui/icons/CloudUpload';




export default ({
  text,
  upload,
  disabled = false,
  ...props
}) => {
  const classes = useStyles({})
  const input = useRef(null)

  return (
    <>
      <input
        {...props}
        ref={input}
        type="file"
        className={classes.input}
        onChange={() => {
          upload(input.current.files)
          input.current.value = ''
        }}
        disabled={disabled}
      />
      <Fab
        color="primary"
        aria-label="Upload"
        className={classes.fab}
        variant={text ? 'extended' : 'round'}
        onClick={() => input.current.click()}
        disabled={disabled}
      >
        <CloudUpload className={text ? classes.fabIcon : ''} />
        {!!text && text}
      </Fab>
    </>
  )
}
