import React, { useState, useEffect } from "react";
import CompanyForm from "./CompanyForm";
import Modal from "../Modal";
export default ({ onSubmit, selectedCompany, open, handleClose }) => {
  const [header, setHeader] = useState("");
  useEffect(() => {
    if (open) {
      if (selectedCompany) setHeader("Edit Company");
      else setHeader("Add Company");
    }
  }, [header, selectedCompany, open]);
  return (
    <Modal open={open} header={header} handleClose={handleClose}>
      <CompanyForm
        onSubmit={onSubmit}
        company={selectedCompany}
        onCancel={handleClose}
      />
    </Modal>
  );
};
