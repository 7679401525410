import React, { useState, useEffect, useRef } from "react";
import { TreeView, TreeItem } from "@material-ui/lab";
import { makeStyles, Backdrop, CircularProgress, IconButton, Box } from "@material-ui/core";
import { FolderSharp, FolderOpenSharp, GetApp, Publish, Delete } from "@material-ui/icons";
import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import UploadNewFiles from "./UploadNewFiles";
import Alert from "../Alert";
import ConfirmDialog from "../ConfirmDialog";
import SearchBar from "./SearchBar";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
        margin: "auto",
        marginTop: theme.spacing(4),
        width: "50%",
    },
    label: {
        color: "rgba(0, 0, 0, 0.54)",
        textAlign: "left",
        margin: theme.spacing(1)
    },
    backdrop: {
        zIndex: 1,
        color: '#fff',
    },
    innerBox: {
        width: "50%"
    }
}));

export default () => {
    const classes = useStyles();
    const inputFile = useRef(null);
    const [blobs, setBlobs] = useState({});
    const [filteredData, setFilteredData] = useState({});
    const [openedContainer, setOpenedContainer] = useState(null);
    const [loading, setLoading] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [alertData, setAlertData] = useState({
        open: false,
        message: "",
        title: "",
        severity: "",
    });
    const [dialogData, setDialogData] = useState({
        open: false,
        message: "",
        title: "",
        agree: "",
        handleAgree: () => { },
        disagree: "",
        handleDisagree: () => { },
    });

    const fetchData = async () => {
        setLoading(true);
        const blobService = new BlobServiceClient(
            process.env.REACT_APP_BLOB_SERVICE_URL
        );
        const data = {};
        const containers = ["101-forms-2021", "id-files-2021", "tax-coordination-files-2021"];
        Promise.all(
            containers.map(async containerName => {
                data[containerName] = [];
                const containerClient = blobService.getContainerClient(containerName);
                for await (const blob of containerClient.listBlobsFlat()) {
                    data[containerName].push(blob.name);
                }
            }))
            .then(_ => {
                setBlobs(data);
                setFilteredData(data);
                setLoading(false);
            });
    }

    useEffect(() => {
        fetchData();
    }, []);

    const downloadFile = (containerName, blob) => {
        const blobService = new BlobServiceClient(
            process.env.REACT_APP_BLOB_SERVICE_URL
        );
        const container = blobService.getContainerClient(containerName);
        const blobClient = container.getBlobClient(blob);
        blobClient.download()
            .then((result) => {
                result.blobBody.then((file) => {
                    saveAs(file, blob.substring(blob.lastIndexOf('/') + 1));
                });
            })
            .catch((e) => console.error(e))
    };

    const uploadInvoice = (containerName, file) => {
        try {
            const blobService = new BlobServiceClient(
                process.env.REACT_APP_BLOB_SERVICE_URL
            );
            const container = blobService.getContainerClient(containerName);
            const blockBlobClient = container.getBlockBlobClient(fileToUpload);
            setLoading(true);
            blockBlobClient.uploadBrowserData(file).then(_ => {
                setLoading(false);
                setAlertData({
                    open: true,
                    message: "The file was re-uploaded successfully",
                    title: "success",
                    severity: "success",
                });
            })
        }
        catch (error) {
            setAlertData({
                open: true,
                message: "There was an error re-uploading the file",
                title: "Error",
                severity: "error"
            });
        }
    }

    const onUpdateButtonClick = (blob) => {
        setFileToUpload(blob)
        inputFile.current.click();
    };

    const deleteFile = (containerName, blob) => {
        setLoading(true);
        const blobService = new BlobServiceClient(
            process.env.REACT_APP_BLOB_SERVICE_URL
        );
        const container = blobService.getContainerClient(containerName);
        const blobClient = container.getBlobClient(blob);
        blobClient.delete()
            .then(_ => {
                setAlertData({
                    open: true,
                    message: "The file was deleted successfully",
                    title: "success",
                    severity: "success",
                });
                fetchData();
            })
            .catch(_ => {
                setAlertData({
                    open: true,
                    message: "An error occurred while deleting the file",
                    title: "Error",
                    severity: "error"
                });
                setLoading(false);
            })
    }

    const handleClose = () => {
        setDialogData({ ...dialogData, open: false })
    }

    const handleDelete = (containerName, blob) => {
        setDialogData({
            open: true,
            message: <>Are you sure you wnat to delete the file: <b>{blob}</b> ?</>,
            title: "Deleting file",
            agree: "Yes",
            handleAgree: _ => {
                handleClose();
                deleteFile(containerName, blob);
            },
            disagree: "No",
            handleDisagree: handleClose,
        })
    }

    const renderTree = () => (
        Object.entries(filteredData).map(([containerKey, container]) => {
            return container.length > 0 &&
                <TreeItem
                    key={containerKey}
                    nodeId={containerKey}
                    label={containerKey.substring(0, containerKey.lastIndexOf('-')).replace(/-/g, ' ').toUpperCase()}
                    className={classes.label}>
                    {Object.values(container).map((blob) => {
                        return <TreeItem
                            key={blob}
                            nodeId={blob}
                            label={
                                <>
                                    <IconButton onClick={_ => { downloadFile(containerKey, blob) }}><GetApp color="primary" /></IconButton>
                                    <IconButton onClick={_ => onUpdateButtonClick(blob)}><Publish color="primary" /></IconButton>
                                    <input type="file" id="icon-button-file" ref={inputFile} style={{ display: 'none' }} onChange={(event) => uploadInvoice(containerKey, event.target.files[0])} />
                                    <IconButton onClick={_ => { handleDelete(containerKey, blob) }}><Delete color="primary" /></IconButton>
                                    {blob}
                                </>
                            }
                            className={classes.label} />
                    })
                    }
                </TreeItem >
        })
    )

    return (
        <>
            <UploadNewFiles
                containerName={openedContainer}
                setLoading={setLoading}
                setAlertData={setAlertData}
                fetchData={fetchData} />
            <Box display="flex" justifyContent="center" bgcolor="default" p={1} m={1}>
                <Box p={1} className={classes.innerBox}>
                    <SearchBar data={blobs} setFilteredData={setFilteredData} />
                </Box>
            </Box>
            <TreeView className={classes.root}
                defaultCollapseIcon={<FolderOpenSharp color="primary" />}
                defaultExpandIcon={<FolderSharp color="primary" />}
                onNodeToggle={(event, nodes) => setOpenedContainer(nodes[0])}
            >
                {renderTree()}
            </TreeView>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Alert
                closeModal={() => setAlertData({ open: false })}
                alertData={alertData}
            />
            <ConfirmDialog dialogData={dialogData} />
        </>
    )
}