import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}));

const getActiveTab = () => {
  const tabs = ["/invoices/new", "/invoices/archive", "/invoices/companies", "/invoices/summary"];
  const index = tabs.indexOf(window.location.pathname);

  return index > 0 ? index : 0;
};

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(getActiveTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root} >
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="generate" component={Link} to="/invoices/new" />
        <Tab label="archive" component={Link} to="/invoices/archive" />
        <Tab label="companies" component={Link} to="/invoices/companies" />
        <Tab label="summary" component={Link} to="/invoices/summary" />
      </Tabs>
    </Paper>
  );
};
