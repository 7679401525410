import { useState, useEffect } from "react";
import { BlobServiceClient } from "@azure/storage-blob";

export default () => {
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    const blobService = new BlobServiceClient(
      process.env.REACT_APP_BLOB_SERVICE_URL
    );
    const container = blobService.getContainerClient("companies");

    const blobClient = container.getBlobClient(process.env.REACT_APP_BLOB_NAME);
    fetch(blobClient.url)
      .then((res) => res.json())
      .then((data) => {
        setCompanies(data);
      });
  }, []);

  return companies;
};

export const getCompany = (companies, department) => {
  const company =  Object.entries(companies).find(
    ([code, company]) =>
      company.name === department ||
      company.hebName === department ||
      (company.aliases && company.aliases.indexOf(department) !== -1)
  );

  return company ? {code: company[0], ...company[1]} : {name: department, code: department};
};