import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    fab: {
      margin: spacing(3, 0, 0, 3),
    },
    loader: {
      margin: "auto",
      marginTop: spacing(3)
    },
    input: {
      display: "none",
    },
    fabIcon: {
      marginRight: spacing(1),
    },
    alert: {
      width: '50vw',
    },
    backdrop: {
      zIndex: "100!important",
      color: '#fff',
    },
  })
);


