import formatDate from "date-fns/format";
import { BlobServiceClient } from "@azure/storage-blob";
import { saveAs } from "file-saver";
import { format } from "util";
import {capitalize} from "@material-ui/core";

export const download = (data, companyName, date) => {
  if (data.expenses.length > 0) {
    const filename = `${companyName} Invoice ${formatDate(date, "MM-yy")}`;

    const year = formatDate(date, "yyyy");
    const month = formatDate(date, "MMMM");
    const filePath = `${year}/${month}/${filename}`;
    const url = format(
      process.env.REACT_APP_GENERATE_INVOICE_ENDPOINT,
      filePath
    );

    return fetch(url, {
      method: "POST",
      body: parse(data),
    }).then((response) => {
      if (response.ok) {
        const blobService = new BlobServiceClient(
          process.env.REACT_APP_BLOB_SERVICE_URL
        );

        const container = blobService.getContainerClient("invoices");
        const blobClient = container.getBlobClient(`${filePath}.xlsx`);
        blobClient
          .download()
          .then((result) => {
            result.blobBody.then((blob) => {
              saveAs(blob, `${filename}.xlsx`);
            });
          })
          .catch((e) => console.error(e));
      }
    });
  }
};

const capitalizeKeys = (obj) => {
  const entries = Object.entries(obj).map((entry) => {
    const key = capitalize(entry[0]);
    let value = entry[1];
    if (value instanceof Array) {
      value = value.map(capitalizeKeys);
    }
    return [key, value];
  });

  return Object.fromEntries(entries);
};

const parse = (obj) => {
  return JSON.stringify(capitalizeKeys(obj));
}
