import React, { useEffect, useState } from "react";
import {
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Backdrop,
    CircularProgress
} from "@material-ui/core";
import { useStyles } from "./SummaryClasses";
import { BlobServiceClient } from "@azure/storage-blob";
import { MONTHS_LIST } from "../MonthsList";
import SummaryTable from "./SummaryTable";
import { initializeTableData, addInvoice } from "./InitializeSummaryData";
import Alert from "../Alert";


const blobService = new BlobServiceClient(
    process.env.REACT_APP_BLOB_SERVICE_URL
);
const container = blobService.getContainerClient("invoices");

export default () => {
    const classes = useStyles({});
    const [selectedYear, setSelectedYear] = useState('');
    const [optionsYears, setOptionsYears] = useState();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertData, setAlertData] = useState({
        open: false,
        message: "",
        title: "",
        severity: "",
    });
    const failedProcessingFile = (fileName) => {
        setAlertData({
            open: true,
            message: <>An error occurred while processing the following invoice data: <br /><b>{fileName}</b></>,
            title: "Error",
            severity: "error",
        });
    }

    const companies = {}
    const months = {};
    MONTHS_LIST.map(m => months[m] = { sum: null, rate: null });

    useEffect(() => {
        try {
            const getYears = async () => {
                setLoading(true);
                let years = [];
                let blobs = container.listBlobsByHierarchy("/", { prefix: "" });
                for await (const blob of blobs) {
                    if (blob.kind === "prefix") {
                        years.push(blob.name);
                    }
                }
                setOptionsYears(years);
                setLoading(false);
            };
            getYears();
        }
        catch (e) {
            console.log(e);
        }
    }, []);

    const downloadFile = async (containerClient, blockBlobName, month) => {
        return new Promise(async (resolve, reject) => {
            try {
                const blobClient = containerClient.getBlobClient(blockBlobName);
                const file = await blobClient.download();
                file.blobBody.then(res => {
                    addInvoice(blockBlobName, res, month, companies, months)
                        .then(_ => {
                            resolve(true);
                        })
                        .catch(e => {
                            failedProcessingFile(blockBlobName);
                            resolve(true);
                        })
                })
            }
            catch (e) {
                failedProcessingFile(blockBlobName);
                resolve(true);
            }
        })
    }

    useEffect(() => {
        const extractMonthName = blobName => blobName.split('/')[1];

        const getDataByYear = async () => {
            let arr = [];
            //get all months
            for await (const blob of container.listBlobsByHierarchy("/", {
                prefix: selectedYear,
            })) {
                //for each month, get all invoices
                if (blob.kind === "prefix") {
                    let month = extractMonthName(blob.name)
                    let listBlobs = container.listBlobsByHierarchy("/", { prefix: blob.name });
                    for await (const blob of listBlobs) {
                        if (blob.kind === "blob") {
                            arr.push(downloadFile(container, blob.name, month));
                        }
                    }
                }
            }
            Promise.all(arr).then(_ => {
                setLoading(false);
                initializeTableData(months, companies, setData);
            })
        };

        if (selectedYear !== '') {
            setLoading(true);
            getDataByYear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear]);

    const onSelectYear = (event) => {
        setSelectedYear(event.target.value);
    };

    const extractYear = year => year.split('/')[0];

    return (
        <>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">Select a year</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedYear}
                    disabled={loading}
                    onChange={e => onSelectYear(e)}
                >
                    {optionsYears &&
                        optionsYears.map((i, k) =>
                            <MenuItem key={k} value={i}>{extractYear(i)}</MenuItem>
                        )
                    }
                </Select>
            </FormControl>
            <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Alert
                closeModal={() => setAlertData({ open: false })}
                alertData={alertData}
            ></Alert>
            <SummaryTable data={data} year={selectedYear.replace(/\//g, '')}></SummaryTable>
        </>
    );
};
