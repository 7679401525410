import React, { useEffect, useState, useRef } from 'react';
import { TreeView, TreeItem } from "@material-ui/lab";
import { BlobServiceClient } from "@azure/storage-blob";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { saveAs } from "file-saver";
import { MONTHS_LIST } from "../MonthsList";
import { FolderSharp, FolderOpenSharp, GetApp, Publish } from "@material-ui/icons";
import { Box, IconButton, CircularProgress, Backdrop } from '@material-ui/core';
import Alert from "../Alert";
import UploadNewInvoices from './UploadNewInvoices';


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(2),
      margin: "auto",
      width: "30%",
      [theme.breakpoints.down('lg')]: {
        width: "50%"
      }
    },
    label: {
      color: "rgba(0, 0, 0, 0.54)",
      textAlign: "left",
      margin: theme.spacing(1)
    },
    backdrop: {
      zIndex: 1,
      color: '#fff',
    },
  })
);

export default () => {
  const [blobs, setBlobs] = useState({});
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const inputFile = useRef(null);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [alertData, setAlertData] = useState({
    open: false,
    message: "",
    title: "",
    severity: "",
  });

  const fetchData = async () => {
    setLoading(true);
    const blobService = new BlobServiceClient(
      process.env.REACT_APP_BLOB_SERVICE_URL
    );
    const byYearAndByMonth = {};
    const container = blobService.getContainerClient("invoices");
    for await (const blob of container.listBlobsFlat()) {
      const year = blob.name.substring(0, 4)
      const month = blob.name.substring(5, blob.name.lastIndexOf('/'))
      if (typeof byYearAndByMonth[year] === "undefined") {
        byYearAndByMonth[year] = [];
      }
      if (typeof byYearAndByMonth[year][month] === "undefined") {
        byYearAndByMonth[year][month] = [];
      }
      byYearAndByMonth[year][month].push(blob.name);
    }
    setBlobs(byYearAndByMonth);
    setLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  const downloadInvoice = (blob) => {
    const blobService = new BlobServiceClient(
      process.env.REACT_APP_BLOB_SERVICE_URL
    );
    const container = blobService.getContainerClient("invoices");
    const blobClient = container.getBlobClient(blob);
    blobClient.download().then((result) => {
      result.blobBody.then((invoice) => {
        saveAs(invoice, blob.substring(blob.lastIndexOf('/') + 1));
      });
    })
      .catch((e) => console.error(e))
  };

  const onUpdateButtonClick = (blob) => {
    setFileToUpload(blob)
    inputFile.current.click();
  };

  const uploadInvoice = (file) => {
    try {
      const blobService = new BlobServiceClient(
        process.env.REACT_APP_BLOB_SERVICE_URL
      );
      const container = blobService.getContainerClient("invoices");
      const blockBlobClient = container.getBlockBlobClient(fileToUpload);
      setLoading(true);
      blockBlobClient.uploadBrowserData(file).then(_ => {
        setLoading(false);
        setAlertData({
          open: true,
          message: "The file was re-uploaded successfully",
          title: "success",
          severity: "success",
        });
      })
    }
    catch (error) {
      setAlertData({
        open: true,
        message: "There was an error re-uploading the file",
        title: "Error",
        severity: "error"
      });
    }
  }

  const getLastSelected = (nodes) => {
    if (!nodes || nodes.length <= 1)
      return null;
    if (nodes[0].indexOf("_") > 0) {
      if (nodes.includes(nodes[0].split("_")[0])) {
        return nodes[0];
      }
      else {
        return getLastSelected(nodes.slice(1));
      }
    }
    else {
      let last = nodes.slice(1).find(i => i.includes(nodes[0]));
      return last || getLastSelected(nodes.slice(1));
    }
  }

  const handleChange = (event, nodes) => {
    let selected = getLastSelected(nodes);
    if (selected) {
      const [year, month] = selected.split("_");
      setSelectedYear(year);
      setSelectedMonth(month);
    }
    else {
      setSelectedYear(null);
      setSelectedMonth(null);
    }
  }

  const renderTree = () => (
    Object.entries(blobs).map(([yearKey, year]) => {
      return (
        <TreeItem key={yearKey} nodeId={yearKey} label={yearKey} className={classes.label}>
          {Object.entries(year).sort((a, b) => { return MONTHS_LIST.indexOf(a[0]) - MONTHS_LIST.indexOf(b[0]) }).map(([monthKey, month]) => {
            return (
              <TreeItem key={yearKey + monthKey} nodeId={yearKey + "_" + monthKey} label={monthKey} className={classes.label} >
                {Object.values(month).map((blob) => {
                  return (
                    <TreeItem
                      key={blob}
                      nodeId={blob}
                      label={<>
                        <IconButton onClick={_ => { downloadInvoice(blob) }}><GetApp color="primary" /></IconButton>
                        <IconButton onClick={_ => onUpdateButtonClick(blob)}><Publish color="primary" /></IconButton>
                        <input type="file" accept=".xlsx" id="icon-button-file" ref={inputFile} style={{ display: 'none' }} onChange={(event) => uploadInvoice(event.target.files[0])} />
                        {blob.substring(blob.lastIndexOf('/') + 1)}
                      </>}
                      className={classes.label}
                    />
                  )
                })}
              </TreeItem>
            )
          })
          }
        </TreeItem>
      )
    })
  );

  return (
    <>
      <Box display="flex" justifyContent="center" bgcolor="default" p={1} m={1}>
        <Box p={1} bgcolor="grey.100">
          {"If you made a manual change to the invoice, please upload the new one so we can sync. "}
        </Box>
      </Box>
      <UploadNewInvoices
        year={selectedYear}
        month={selectedMonth}
        setLoading={setLoading}
        setAlertData={setAlertData}
        fetchData={fetchData} />
      <TreeView className={classes.root}
        multiSelect={false}
        defaultCollapseIcon={<FolderOpenSharp color="primary" />}
        defaultExpandIcon={<FolderSharp color="primary" />}
        // onNodeSelect={handleChange}
        onNodeToggle={handleChange}
      >
        {renderTree()}
      </TreeView>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Alert
        alertData={alertData}
        closeModal={() => setAlertData({ ...alertData, open: false })}
      />
    </>
  );
}
