import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";

export default ({ header, open, handleClose, children }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose} 
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{header}</DialogTitle>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};
