export const setRTL = (wb) => {
  if (!wb.Workbook) wb.Workbook = {};
  if (!wb.Workbook.Views) wb.Workbook.Views = [];
  if (!wb.Workbook.Views[0]) wb.Workbook.Views[0] = {};
  wb.Workbook.Views[0].RTL = true;
};

export const setAutoWidth = (worksheet, aoa) => {
  const objectMaxLength = [];
  aoa.forEach((arr) => {
    arr.forEach((value, key) => {
      let len = value && value.toString().length;
      objectMaxLength[key] = Math.max(objectMaxLength[key] || 0, len || 0);
    });
  });

  let cols = objectMaxLength.map((width) => {
    return {
      width,
    };
  });

  worksheet["!cols"] = cols;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      resolve(e.target.result);
    };
    reader.readAsArrayBuffer(file);
  });
};

export const get = (url) => {
  return fetch(url)
    .then((response) => response.json())
}
