import React from "react";

import FabUploadFile from "../FabUploadFile";
import XLSX from "xlsx";
import { readFile } from "../utils";
import useCompanies, {getCompany} from "../companies/useCompanies";

const TOTAL_COST = 'סה"כ עלות';
const EMPLOYEE_NUMBER = "מספר עובד";
const DEPARTMENT = "שם מחלקה";
const NAME = "בחר עובד";


export default ({ setData }) => {

  const companies = useCompanies();

  const upload = (files) => {
    readFile(files[0]).then((data) => {
      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];

      const rows = XLSX.utils
        .sheet_to_json(sheet)
        .reduce((accumulator, row) => {
          const employeeNumber = row[EMPLOYEE_NUMBER];
          const department = row[DEPARTMENT];

          const company = getCompany(companies, department);

          if (company) {
            accumulator[employeeNumber] = {
              totalCost: row[TOTAL_COST],
              baseCompany: company,
              baseDepartment: department,
              fullName: row[NAME],
            };
          }
          return accumulator;
        });

      setData(rows);
    });
  };

  return (
    <FabUploadFile
      accept=".xlsx"
      upload={upload}
      text="Upload Michpal Costing file"
    ></FabUploadFile>
  );
};
