import React from "react";

import FabUploadFile from "../FabUploadFile";
import XLSX from "xlsx";
import { readFile } from "../utils";


export default ({ setData }) => {
  const upload = (files) => {
    readFile(files[0]).then((data) => {

      const workbook = XLSX.read(data, { type: "array" });
      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const rows = XLSX.utils.sheet_to_json(sheet);

      const DAILY = sheet["E1"].v;
      const MONTHLY = sheet["D1"].v; 
      const EMPLOYEE_NUMBER = sheet["A1"].v; 
      const NAME = sheet["B1"].v; 

      const employeesData = rows.map((e) => ({
        number: e[EMPLOYEE_NUMBER],
        monthlyRate: e[MONTHLY],
        dailyRate: e[DAILY],
        name: e[NAME],
      }));
      setData(employeesData);
    });
  };

  return (
    <FabUploadFile
      accept=".xlsx"
      upload={upload}
      text="Upload Michpal Report"
    ></FabUploadFile>
  );
};
