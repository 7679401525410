import { readFile } from "../utils";
import XLSX from "xlsx";
import { MONTHS_LIST } from "../MonthsList";

const SALARIES = "Salaries (cost)";
const TOTAL_SUM = "Total invoice";
const RATE_DESCRIPTION = "$ rate:";


export const initializeTableData = (months, companies, setData) => {
    const newItem = (text, type) => ({
        text: text,
        type: type
    })

    let data = [];

    //headers
    let headers = [];
    headers.push(newItem(null, null));
    headers.push(newItem(null, null));
    MONTHS_LIST.map(m => headers.push(newItem(m, "month")));
    headers.push(newItem("TOTAL", "total"));
    headers.push(newItem("AVERAGE", "avg"));
    data.push(headers);

    //content
    Object.entries(companies).sort().forEach(([company, value]) => {
        let addCompanyName = true;
        Object.entries(value).forEach(([title, array]) => {
            let content = [];
            if (addCompanyName) {
                content.push(newItem(company, "company"));
                addCompanyName = false;
            }
            else content.push(newItem(null, "none"));
            content.push(newItem(title, "title"));
            let count = 0;
            MONTHS_LIST.forEach(m => {
                if (!(array[m] === null)) count++;
                content.push(newItem(array[m] !== null ? array[m].toFixed(2) : null, "content"));
            });
            content.push(newItem(array.sum !== null ? array.sum.toFixed(2) : null, "calc"));
            content.push(newItem(array.average !== null ? (array.average / count).toFixed(2) : null, "calc"))
            data.push(content);
        })
    })

    //total
    let total = [];
    total.push(newItem(null, "none"));
    total.push(newItem("Overall Total", "footer"));
    let sum = 0;
    let count = 0;
    MONTHS_LIST.forEach(m => {
        sum += months[m].sum;
        if (months[m].sum) count++;
        total.push(newItem(months[m].sum !== null ? months[m].sum.toFixed(2) : null, "calc"));
    });
    total.push(newItem(parseFloat(sum.toFixed(2)), "calc"));
    total.push(newItem(parseFloat((sum / count).toFixed(2)), "calc"));
    data.push(total);

    //rates
    let rates = [];
    rates.push(newItem(null, "none"));
    rates.push(newItem("$ rate", "footer"));
    sum = 0;
    count = 0;
    MONTHS_LIST.forEach(m => {
        if (months[m].rate) {
            sum += parseFloat(months[m].rate);
            count++;
        }
        rates.push(newItem(months[m].rate || null, "rate"));
    })
    rates.push(newItem(null, null));
    rates.push(newItem(parseFloat((sum / count).toFixed(2)), "calc"));
    data.push(rates);

    console.log(data);
    setData(data);
};

const getInvoiceData = (blob) => {
    return new Promise((resolve, reject) => {
        readFile(blob).then((data) => {
            try {
                const workbook = XLSX.read(data, { type: "array" });
                Object.values(workbook.Sheets).forEach((sheet) => {
                    const edge = sheet["!ref"].split(":")[1]; // get the last cell in sheet
                    const data = XLSX.utils.sheet_to_json(sheet, {
                        range: `A1:${edge}`,
                    });
                    resolve(data);
                });
            }
            catch (e) {
                reject(e);
            }
        });
    })
}

const getComapanyName = (blobName) => blobName.split("/").pop().split("Invoice")[0].trim();

const getSalary = (data) => {
    let row = data.find(d => Object.values(d).find(e => e.toString().startsWith(SALARIES)))
    if (row) {
        let s = Object.values(row).filter(r => !isNaN(r) || !isNaN(r.replace(",", ""))).slice(-1)[0];
        return typeof (s) === "number" ? s : parseFloat(s.replace(",", ""))
    }
    else return null;
}

const getTotal = (data) => {
    let row = data.find(d => Object.values(d).includes(TOTAL_SUM));
    return Object.values(row).filter(r => !isNaN(r))[1];
}

const getRate = (data) => {
    let i = data.findIndex(d => Object.values(d).includes(RATE_DESCRIPTION))
    return Object.values(data[i + 1]).filter(r => !isNaN(r)).slice(-1)[0]
}

const addMonthToCompany = (obj) => {
    MONTHS_LIST.map(month => obj[month] = null);
}

const addNewCompany = () => {
    let company = {
        salaries: {
            sum: null,
            average: null
        },
        total: {
            sum: null,
            average: null
        }
    }
    addMonthToCompany(company.salaries);
    addMonthToCompany(company.total);
    return company;
}

export const addInvoice = async (blobName, blob, month, companies, months) => {
    return getInvoiceData(blob).then((data) => {
        let companyName = getComapanyName(blobName);
        let total = getTotal(data);
        let salary = getSalary(data);
        if (!salary && total !== null) {
            salary = 0;
        }
        let rate = getRate(data);

        if (!companies[companyName]) {
            companies[companyName] = addNewCompany();
        }

        companies[companyName].salaries[month] = salary;
        if (salary != null) {
            companies[companyName].salaries.sum += salary;
            companies[companyName].salaries.average += salary;
        }
        companies[companyName].total[month] = total;
        companies[companyName].total.sum += total;
        companies[companyName].total.average += total;

        months[month].sum += total;
        months[month].rate = rate;
    })
};