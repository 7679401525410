import { makeStyles, createStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 180,
        },
        container: {
            margin: theme.spacing(2),
            width: "98.5%"
        },
        table: {
            minWidth: 700,
        },
        backdrop: {
            zIndex: 1,
            color: '#fff',
        },
        company: {
            fontWeight: "bold"
        },
        month: {
            fontWeight: "bold"
        },
        total: {
            fontWeight: "bold"
        },
        avg: {
            fontWeight: "bold"
        },
        footer: {
            textAlign: "center",
            fontWeight: "bold"
        },
        button: {
            margin: theme.spacing(1),
            fontSize: 12
        },
        excelArea: {
            textAlign: "left"
        }
    })
);
