import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Link } from 'react-router-dom'


const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const getActiveTab = () => {
  const tabs = ["travelling-allowance", "invoices", "hours-track", "archive-101", "compensation-calculation"]
  const index = tabs.indexOf(window.location.pathname.split('/')[1])

  return index > 0 ? index : 0;
};

export default () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(getActiveTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="travelling allowance" component={Link} to="/travelling-allowance" />
        <Tab label="invoices" component={Link} to="/invoices" />
        <Tab label="hours track" component={Link} to="/hours-track" />
        <Tab label="archive 101" component={Link} to="/archive-101" />
        <Tab label="compensation calculation" component={Link} to="/compensation-calculation" />
      </Tabs>
    </Paper>
  );
};
